<template>
  <b-container class="overflow-auto" fluid>
    <a @click="goBack" style="border: 1px black"><span class="fa fa-long-arrow-left"></span> Back</a>
    <!-- SM screen -->
    <h5 class="my-3 d-block d-sm-none" style="max-width: 450px; min-width: 140px; word-wrap: break-word;">Showing Native Ads for campaign: <strong class="" >{{ typeof(nativead.list[0])==='undefined' ? '': nativead.list[0].CampaignName }}</strong> </h5>
    <!-- * EXCEPT SM screen -->
    <h5 class="my-3 d-none d-sm-block" >Showing Native Ads for campaign: <strong class="">{{ typeof(nativead.list[0])==='undefined' ? '': nativead.list[0].CampaignName }}</strong> </h5>

    <!-- Ad Interface controls -->
    <a-row>
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'ortb-native-ads'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-nativeads-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :bordered="true"
      :current-page="currentPage"
      :fields="filteredFields"
      :filter="filter"
      :hover="true"
      :items="showNativeAds"
      :per-page="perPage"
      :small="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :sort-compare="sortCompare"
      :striped="true"
      :tbody-tr-class="rowClass"
      show-empty
      stacked="md"
      @filtered="onFiltered"
    >
      <template #cell(Searchfeeds)="data">
        <div class="items-center">
          {{ data.item.Searchfeeds }}
        </div>
      </template>
      <template #cell(Name)="data">
        <div class="items-center">
          {{ data.item.Name }}
        </div>
      </template>
      <template #cell(Rtbzones)="data">
        <div v-if="data.item.Rtbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in rtbzones">
                  {{
                    data.item.Rtbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Rtbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(CampaignName)="data">
        <!-- SM screen -->
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div class="items-center">
            <div v-if="data.item.CampaignName.length<=20">{{ data.item.CampaignName }}</div>
            <div class="text-left" style="max-width: 200px; min-width: 140px; word-wrap: break-word;" v-if="data.item.CampaignName.length>20">{{ data.item.CampaignName }}</div>
          </div>
        </div>
        <!-- * EXCEPT SM screen -->
        <div class="d-none d-sm-block">
          <!-- name slot -->
          <div class="items-center">
            <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName" class="">{{ data.item.CampaignName }}</div>
          </div>
        </div>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleNativeAdStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(actions)="data">
        <router-link class="add-button" @click.stop :to="{ name: 'nativead-update', params: { id: data.item.Id },  }">
          <b-button
            class="btn-info mr-1"
            size="sm"
            @click="editAd(data.item.Id)"
          ><i class="fa fa-edit"></i></b-button>
        </router-link>
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click="copyNativeAd(data.item.Id , data.item)"
        ><i class="fa fa-copy"></i></b-button>
      </template>
    </b-table>
<!-- CampaignName -->
    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <button v-if="campaignStatus===0" class="btn btn-success px-5" @click="redirectToCreateAd()">Create Native Ad</button>
        <b-alert show variant="danger" v-else> Campaign is Deactivated </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import { cloneNativeAd } from '@/api/display/nativead'
import { getCampaigns } from '@/api/display/campaign'
import { getRTBZonesShort } from '@/api/display/rtbzone'

export default {
  props: ['campaign'],
  components: {
    columnSelect,
    perPageSelect,
  },
  data() {
    return {
      campaignStatus: 0,
      days: [
        {
          title: 'Saturday',
          shortcut: 'sat',
        },
        {
          title: 'Sunday',
          shortcut: 'sun',
        },
        {
          title: 'Monday',
          shortcut: 'mon',
        },
      ],
      selectedDays: ['sun'],
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:90px',
        },
        {
          key: 'Id',
          label: 'Ad ID',
          show: true,
          headerTitle: 'Ad ID',
          sortable: true,
          thStyle: 'width:70px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Ad Name',
          show: true,
          headerTitle: 'Ad Name',
          sortable: true,
        },
        {
          key: 'CampaignName',
          label: 'Campaign',
          show: true,
          headerTitle: 'Campaign Name',
          sortable: true,
          thStyle: 'width:180px',
        },
        {
          key: 'DefaultCPM',
          label: 'Default CPM',
          headerTitle: 'Default Cost per Mile',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          formatter: '$formatCurrency',
          thStyle: 'width:110px',
        },
        {
          key: 'BidsDailyLimit',
          label: 'Bids Daily Limit',
          headerTitle: 'Bids Daily Limit',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ImpressionsDailyLimit',
          label: 'Imp. Daily Limit',
          show: true,
          headerTitle: 'Impressions Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ClicksDailyLimit',
          label: 'Clicks Daily Limit',
          show: true,
          headerTitle: 'Clicks Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'SpentDailyLimit',
          label: 'Spent Daily Limit',
          show: true,
          headerTitle: 'Spent Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'AdDomainBrand',
          label: 'Ad Domain/Brand',
          show: false,
          sortable: true,
        },
        {
          key: 'AdAppBundle',
          label: 'Ad App Bundle',
          show: false,
          sortable: true,
        },
        {
          key: 'DestinationUrl',
          label: 'Destination URL',
          show: false,
          sortable: true,
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      rtbzones: null,
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['nativead', 'settings']),
    ...mapGetters('nativead', ['getNativeAds', 'getActiveNativeAds']),
    showActive: {
      get: function () {
        return this.settings.showActiveCampaignNativeAds
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActiveCampaignNativeAds', value })
      },
    },
    showNativeAds() {
      return this.showActive ? this.getActiveNativeAds : this.getNativeAds
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showNativeAds.length
    },
  },
  methods: {
    sortCompare(a, b, key) {
      if (key === 'Rtbzones') {
        const lenA = Array.isArray(a[key]) ? a[key].length : 0
        const lenB = Array.isArray(b[key]) ? b[key].length : 0
        return lenA - lenB
      }
      return a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)
    },
    redirectToCreateAd() {
      // this.$router.push('/display/nativead/create/' + this.$route.params.id)
      this.$router.push({ name: 'create-nativead-campaignId', params: { campaignId: this.$route.params.id } }).catch(() => {})
    },
    goBack() {
      this.$router.go(-1)
    },
    getAd(id) {
      cloneNativeAd(id).then(response => {
        if (response) {
          // this.$router.push('/display/nativead/update/' + response.Nativead.Id)
          this.$router.push({ name: 'nativead-update', params: { id: response.Nativead.Id } }).catch(() => {})
          this.$notification.success({
            message: 'Native Ad cloned',
            description: `Native Ad: ${response.Nativead.Name} has been successfully cloned.`,
          })
        }
      }).catch(error => console.log(error))
    },
    copyNativeAd(id, ad) {
      Modal.confirm({
        title: 'Are you sure you want to clone Native Ad?',
        content: h => <div>{status} Clone Native Ad: <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          this.getAd(id)
        }.bind(this),
      })
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editAd(id) {
      // this.$router.push('/display/nativead/update/' + id)
      this.$router.push({ name: 'nativead-update', params: { id: id } }).catch(() => {})
    },
    toggleNativeAdStatus(ad) {
      const status = ad.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Native Ad status?',
        content: h => <div>{status} Native Ad <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = ad.Status === 0 ? 1 : 0
          this.$store.dispatch('nativead/CHANGE_STATUS', {
            id: ad.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('nativead/LOAD_ADS_FOR_CAMPAIGN', { CampaignId: this.$route.params.id })
    getCampaigns(this.$route.params.id).then(response => {
      if (response) {
        this.campaignStatus = response.Status
      }
    })
    getRTBZonesShort()
      .then(res => {
        this.rtbzones = res
      })
  },
  mounted() {
  },
}
</script>

<style>

</style>
